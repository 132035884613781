if (typeof yaCounter === typeof undefined) {
  var yaCounter = {hit: console.log.bind(console), reachGoal: console.log.bind(console)};
}
if (typeof fbq === typeof undefined) {
  var fbq = console.log.bind(console);
}
function reportNavigation () {
  "use strict";
  var href = this.querySelectorAll('a')[0].getAttribute('href');
  ga('send', 'pageview', href);
  fbq('track', 'ViewContent', {
    content_type: 'section',
    content_name: href});
  yaCounter.hit(href);
}

$('.nav>li').on('activate.bs.scrollspy', reportNavigation);

$('.newsletter-form').submit(function(){
  "use strict";
  ga('send', 'event', 'form', 'submit', 'newsletter-form');
  yaCounter.reachGoal('NewsletterSignUp');
});
