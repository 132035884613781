/*! jQuery NativeSlider
 *  https://github.com/MA3STR0/jquery-nativeslider
 *  horizontal slider using native touch events
/*global document, jQuery*/

(function ($) {
    'use strict';
    $.fn.nativeslider = function () {
        return this.each(function (index, container) {
            var $container = $(container);
            var $slider = $container.find('[data-slider=row]');
            var elementSelector = '.' + $slider.data('slider-element-class');
            var $scrollButtons = $container.find('[data-slider-direction=right], [data-slider-direction=left]');
            var $scrollButtonLeft = $container.find('[data-slider-direction=left]');
            var $scrollButtonRight = $container.find('[data-slider-direction=right]');
            $scrollButtons.bind("click touchstart", function(e) {
                e.preventDefault();
                var $element = $slider.find(elementSelector).eq(0);
                var elementWidth = $element.width() + parseInt($element.css('margin-left')) + parseInt($element.css('margin-right'));
                var direction = this.getAttribute('data-slider-direction') === 'left' ? -2 : +2;
                $slider.animate({
                    scrollLeft: $slider.scrollLeft() + elementWidth * 2 * direction
                }, 'fast');
            });
            function updateSliderButtons(){
                var position = $slider.scrollLeft();
                var width = $slider.width();
                var scrollWidth = $slider[0].scrollWidth;
                // Left
                if (position < 10) {
                    $scrollButtonLeft.addClass('hidden');
                } else {
                    $scrollButtonLeft.removeClass('hidden');
                }
                // Right
                if (position > (scrollWidth - width - 10)) {
                    $scrollButtonRight.addClass('hidden');
                } else {
                    $scrollButtonRight.removeClass('hidden');
                }
            }
            $slider.scroll(updateSliderButtons);
            window.addEventListener('resize', updateSliderButtons);
            updateSliderButtons();
        });
    };
    $(document).ready(function () {
        $('[data-slider=container]').nativeslider();
    });
}(jQuery));
