// MailChimp code

if ($("form#mc-embedded-subscribe-form").length) {

    var mc = window.mc;
    window.fnames = [];
    window.ftypes = [];
    window.fnames[1] = 'NAME';
    window.ftypes[1] = 'text';
    window.fnames[0] = 'EMAIL';
    window.ftypes[0] = 'email';

    var err_style = '#mc_embed_signup input.mce_inline_error { border-color:#6B0505; } #mc_embed_signup div.mce_inline_error { margin: 0 0 1em 0; padding: 5px 10px; background-color:#6B0505; font-weight: bold; z-index: 1; color:#fff; }';
    var head = document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = err_style;
    } else {
        style.appendChild(document.createTextNode(err_style));
    }
    head.appendChild(style);

    // Expose extra mc form methods in global var
    window.mc = {
        getAjaxSubmitUrl: function () {
            "use strict";
            var url = $("form#mc-embedded-subscribe-form").attr("action");
            url = url.replace("/post?u=", "/post-json?u=");
            url += "&c=?";
            return url;
        },
        /**
         *    Classify text inputs in the same field group as group for validation purposes.
         *    All this does is tell jQueryValidation to create one error div for the group, rather
         *    than one for each input. Primary use case is birthday and date fields, where we want
         *    to display errors about the inputs collectively, not individually.
         *
         *    NOTE: Grouping inputs will give you one error div, but you still need to specify where
         *    that div should be displayed. By default, it's inserted after the first input with a
         *    validation error, which can break up a set of inputs. Use the errorPlacement setting in
         *    the validator to control error div placement.
         */
        getGroups: function () {
            "use strict";
            var groups = {};
            $(".mc-field-group").each(function () {
                var inputs = $(this).find("input:text:not(:hidden)");	// TODO: What about non-text inputs like number?
                if (inputs.length > 1) {
                    var mergeName = inputs.first().attr("name");
                    var fieldNames = $.map(inputs, function (f) {
                        return f.name;
                    });
                    groups[mergeName.substring(0, mergeName.indexOf("["))] = fieldNames.join(" ");
                }
            });
            return groups;
        },
        /**
         *    Chick if a field is part of a multipart field
         *    (e.g., A date merge field is composed of individual inputs for month, day and year)
         *    Used in jQuery validation onkeyup method to ensure that we don't evaluate a field
         *  if a user hasn't reached the last input in a multipart field yet.
         */
        isMultiPartField: function (element) {
            "use strict";
            return ($('input:not(:hidden)', $(element).closest(".mc-field-group")).length > 1);
        },
        /**
         *    Checks if the element is the last input in its fieldgroup.
         *    If the field is not the last in a set of inputs we don't want to validate it on certain events (onfocusout, onblur)
         *    because the user might not be finished yet.
         */
        isTooEarly: function (element) {
            "use strict";
            var fields = $('input:not(:hidden)', $(element).closest(".mc-field-group"));
            return ($(fields).eq(-1).attr('id') !== $(element).attr('id'));
        },
        /**
         *    Handle the error/success message after successful form submission.
         *    Success messages are appended to #mce-success-response
         *    Error messages are displayed with the invalid input when possible, or appended to #mce-error-response
         */
        mce_success_cb: function (resp) {
            "use strict";
            $('#mce-success-response').hide();
            $('#mce-error-response').hide();

            // On successful form submission, display a success message and reset the form
            if (resp.result === "success") {
                $('#mce-' + resp.result + '-response').show();
                $('#mce-' + resp.result + '-response').html(resp.msg);
                $('#mc-embedded-subscribe-form').each(function () {
                    this.reset();
                });

                // If the form has errors, display them, inline if possible, or appended to #mce-error-response
            } else {
                // Try to parse the error into a field index and a message.
                // On failure, just put the dump thing into in the msg variable.
                var index = -1;
                var msg;
                try {
                    var parts = resp.msg.split(' - ', 2);
                    if (parts[1] === undefined) {
                        msg = resp.msg;
                    } else {
                        var i = parseInt(parts[0]);
                        if (i.toString() === parts[0]) {
                            index = parts[0];
                            msg = parts[1];
                        } else {
                            index = -1;
                            msg = resp.msg;
                        }
                    }
                } catch (e) {
                    index = -1;
                    msg = resp.msg;
                }

                try {
                    // If index is -1 if means we don't have data on specifically which field was invalid.
                    // Just lump the error message into the generic response div.
                    if (index === -1) {
                        $('#mce-' + resp.result + '-response').show();
                        $('#mce-' + resp.result + '-response').html(msg);

                    } else {
                        var fieldName = $("input[name*='" + window.fnames[index] + "']").attr('name'); // Make sure this exists (they haven't deleted the fnames array lookup)
                        var data = {};
                        data[fieldName] = msg;
                        mc.mce_validator.showErrors(data);
                    }
                } catch (e) {
                    $('#mce-' + resp.result + '-response').show();
                    $('#mce-' + resp.result + '-response').html(msg);
                }
            }
        }
    };

    window.mc.mce_validator = $("#mc-embedded-subscribe-form").validate({

        // Set error HTML: <div class="mce_inline_error"></div>
        errorClass: "mce_inline_error",
        errorElement: "label",

        // Validate fields on keyup, focusout and blur.
        onkeyup: false,
        onfocusout: function (element) {
            "use strict";
            if (!mc.isTooEarly(element)) {
                $(element).valid();
            }
        },
        onblur: function (element) {
            "use strict";
            if (!mc.isTooEarly(element)) {
                $(element).valid();
            }
        },
        // Grouping fields makes jQuery Validation display one error for all the fields in the group
        // It doesn't have anything to do with how the fields are validated (together or separately),
        // it's strictly for visual display of errors
        groups: mc.getGroups(),
        // Place a field's inline error HTML just before the div.mc-field-group closing tag
        errorPlacement: function (error, element) {
            "use strict";
            element.closest('.mc-field-group').append(error);
        },
        // Submit the form via ajax (see: jQuery Form plugin)
        submitHandler: function (form) {
            "use strict";
            $(form).ajaxSubmit(mc.ajaxOptions);
        }
    });

    window.mc.ajaxOptions = {
        url: mc.getAjaxSubmitUrl(),
        type: 'GET',
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        success: mc.mce_success_cb
    };
}
