// var isRoot = location.pathname === "/";

// check if page is already in scrolled state (reload)
// var scrolled = window.pageYOffset > 100;

var showIntro = true; // window.newUser;// && isRoot && !scrolled && !window.location.hash;

// Scroll links
function scrollTo(field) {
    var scrollTop = field.offset().top - 30;
    $('html,body').animate({scrollTop: scrollTop});
}

$('.scroll-to').click(function () {
    var target = $(this).data('scroll-to');
    scrollTo($(target));
});

// toggle speakers
var showSpeakers = 8;
var $speakers = $('.slide-gurus .speaker');
var speakersVisible = showSpeakers;
$speakers.slice(speakersVisible).hide();
$('.show-more-speakers').click(function () {
    speakersVisible = speakersVisible + showSpeakers;
    $speakers.slice(speakersVisible - showSpeakers, speakersVisible).slideDown();
    if (speakersVisible >= $speakers.length) {
        $(this).hide();
    }
    return false;
});

// timer
function countDownTimer($elem) {
    var dt = $elem.data('countdown')
    var elem = $elem.get(0);
    var end = new Date(dt);
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;

    function showRemaining() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
            clearInterval(timer);
            elem.innerHTML = 'NOW!';
            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        elem.innerHTML = "";
        if (days) {
        elem.innerHTML += days + (days===1 ? ' day ' : ' days ');
        }
        if (hours) {
        elem.innerHTML += hours + (hours===1 ? ' hour ' : ' hours ');
        }
        if (minutes) {
        elem.innerHTML += minutes + (minutes===1 ? ' minute ' : ' minutes ');
        }
        elem.innerHTML += seconds + ' seconds';
    }

    timer = setInterval(showRemaining, 1000);
}

function initEntryEffect(showIntro) {
    var lines = $('noscript#typeit-text').text().split(';');
    var strings = [];
    var string = "";
    for (var i = 0; i < lines.length; i++) {
        string = lines[i].trim();
        if (string.length) {
            strings.push(string);
        }
    }
    if (showIntro) {
        $('.slide-hero .typeit').typeIt({
            strings: strings,
            lifeLike: false,
            speed: 15,
            breakDelay: 90,
            startDelay: 600,
            cursor: false
        }, function () {
            $('.join-now.placeholder').remove();
            $('.join-now').removeClass('hidden');
        });
    }
}

function initModals() {
    if (window.location.href.indexOf('/thank-you/') !== -1) {
        $('#modal-thank-you').modal('show');
    }
}

function initShowMore() {
    var $limitHeight = $('.limit-height');
    var $showMore = $('.show-more');

    if ($limitHeight.length) {
        var style = window.getComputedStyle($limitHeight[0]);
        var maxHeight = parseInt(style.getPropertyValue('max-height').replace("px", ""));
        if ($limitHeight.height() >= maxHeight) {
            $showMore.removeClass('hidden');
        } else {
            $showMore.remove();
            $limitHeight.removeClass('limit-height');
        }
        $showMore.click(function () {
            $showMore.remove();
            $limitHeight.removeClass('limit-height');
        });
    }
}

function scaleVideo() {
    var $video = $('.video');
    var width = $video.width();
    $video.css('height', Math.round(width*0.462));
}

function initTeaserSpeakers() {
    var $teaser = $('#teaser-speakers-row');
    var $group = $teaser.find('.group');
    var teaser_width = $teaser.width();
    $teaser.scrollLeft(0);
    $teaser.find('.button-right').click(function(){
    var maxScroll = $teaser[0].scrollWidth - $teaser[0].clientWidth;
    var currentScroll = $teaser.scrollLeft();
    if (currentScroll + 20 >= maxScroll) {
        $teaser.animate({scrollLeft: 0})
    } else {
        $teaser.animate({scrollLeft: currentScroll + $group.width()})
    }
    });
    $(window).bind('resize', function(e) {
    if (teaser_width !== $teaser.width()) {
        $teaser.scrollLeft(0);
        teaser_width = $teaser.width();
    }
    });
}

function initGallery() {
    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });
}
function initNavbar() {
    function checkScroll(){
        var startY = $('.navbar').height() * 1; //The point where the navbar changes in px

        if($(window).scrollTop() > startY){
            $('.navbar').addClass("scrolled");
        }else{
            $('.navbar').removeClass("scrolled");
        }
    }
    if($('.navbar').length > 0){
        $(window).on("scroll load resize", function(){
            checkScroll();
        });
    }
}

function initAnchors() {
    // The function actually applying the offset
    function offsetAnchor() {
    if (location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY - 100);
    }
    }

    // Captures click events of all <a> elements with href starting with #
    $(document).on('click', 'a[href^="#"]', function(event) {
    // Click events are captured before hashchanges. Timeout
    // causes offsetAnchor to be called after the page jump.
    window.setTimeout(function() {
        offsetAnchor();
    }, 0);
    });

    // Set the offset when entering page with hash present in the url
    window.setTimeout(offsetAnchor, 0);
}

function styleTweets() {
    let styles = {
        'border-style': 'none',
        'background': '#2c0852'
    };
    let tweetContainer = document.querySelector('.tweet-row');
    for (let i = 0; i < window.twitter_ids.length; i++) {
        let twitterId = window.twitter_ids[i];
        // promise = twttr.widgets.createTweet(String(twitterId), );
        // promise.then(function (el) {
        //     $(el.shadowRoot).find(".EmbeddedTweet").css(styles);
        // });


        console.log('twitter function', twttr.widgets.createTweet)
        twttr.widgets.createTweet(String(twitterId), tweetContainer, {
          // conversation : 'none',
          // cards: 'hidden',
          theme: 'dark'
        }).then(function (el) {
            console.log("Element:", el);
          $(el.shadowRoot).find(".EmbeddedTweet").css(styles);
        });

    }
}

function initVideoClick() {
    let video_box = document.querySelector('.video-box');
    let click_catcher = $('.trailer-click-catcher');
    click_catcher.click(function() {
        video_box.classList.add('playing');
        video_box.classList.add('flicking');
        video_box.classList.remove('hover');
        jQuery(".video-box iframe")[0].src += "&autoplay=1";
        click_catcher.remove();
        // detect scroll after 3 secs
        setTimeout(function(){
            window.onscroll = function (e) {
                video_box.classList.remove('flicking');
                window.onscroll = null;
            }
        }, 3000)
    });
    // let boxStyle = window.getComputedStyle(video_box);
    // let colorRe = new RegExp('(?<color>rgba?\([^)]*\)).*');
    // let color = colorRe.exec(boxStyle.boxShadow).groups.color + ')';
    // let newShadow = color + ' 0px 0px 160px 0px';
    // video_box.style.boxShadow = newShadow;

}
function initVideoHoverGlow() {
    let video_box = document.querySelector('.video-box');
    let click_catcher = $('.trailer-click-catcher');
    click_catcher.hover(function () {
        //over
        video_box.classList.add('hover');
    }, function() {
        // out
        video_box.classList.remove('hover');
    })
}

// $(document).ready(function () {
//     var $map = $('.slide-map');
//     $map.find('iframe').addClass('scrolloff');
//     $map.on('click', function () {
//         $map.find('iframe').removeClass('scrolloff');
//     });
//     $map.mouseleave(function () {
//         $map.find('iframe').addClass('scrolloff');
//     });
//     // run full or minimal effects
//     // initEntryEffect(showIntro);
//     // initTeaserSpeakers();
//     scaleVideo();
//     var countdown = $('.ticket-countdown');
//     if (countdown.length) {countDownTimer(countdown)}
// });
// new WOW().init();
initGallery();
initVideoClick();
initVideoHoverGlow();
initModals();

// styleTweets();
// initShowMore();
// initNavbar();
// initAnchors();
//
// window.addEventListener('resize', function(){
//     scaleVideo();
// });

